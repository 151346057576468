import * as S from "./Footer.styles";

const Footer = () => {
  return (
    <S.StyledFooter>
      <S.Navigation>
        <S.NavLink href="/">Kontakt</S.NavLink>
        <S.NavLink href="/about">Regulamin</S.NavLink>
      </S.Navigation>
    </S.StyledFooter>
  );
};

export default Footer;
