import styled from "styled-components";
import DesktopBackground from "./assets/DesktopBackground.svg";

export const StyledContainer = styled.div`
  display: flex;
  padding-top: 12px;
  justify-content: center;
  background-image: url(${DesktopBackground});
  background-repeat: no-repeat;
`;
