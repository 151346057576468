const LogoLight = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={70} height={27} fill="none">
    <g clipPath="url(#a)">
      <path
        fill="#0D141C"
        d="M67.603 26.88a2.164 2.164 0 0 0 2.157-2.17c0-1.199-.966-2.17-2.157-2.17a2.164 2.164 0 0 0-2.158 2.17c0 1.198.966 2.17 2.158 2.17Z"
      />
      <path
        fill="#3EC273"
        d="M54.897 26.88c-1.637 0-3.109-.332-4.414-.994a7.46 7.46 0 0 1-3.087-2.882c-.753-1.259-1.129-2.772-1.129-4.538v-.53c0-1.767.376-3.28 1.129-4.538a7.46 7.46 0 0 1 3.087-2.882c1.305-.663 2.777-.994 4.414-.994 1.638 0 3.11.331 4.415.994a7.46 7.46 0 0 1 3.087 2.882c.752 1.258 1.128 2.771 1.128 4.538v.53c0 1.766-.376 3.28-1.128 4.538a7.46 7.46 0 0 1-3.087 2.882c-1.305.662-2.777.993-4.415.993Zm0-3.71c1.284 0 2.346-.41 3.187-1.226.84-.84 1.261-2.032 1.261-3.578v-.33c0-1.547-.42-2.728-1.261-3.545-.82-.84-1.881-1.259-3.187-1.259-1.283 0-2.345.42-3.186 1.259-.841.817-1.262 1.998-1.262 3.544v.331c0 1.546.42 2.739 1.262 3.578.84.817 1.903 1.225 3.186 1.225Z"
      />
      <path
        fill="#3EC273"
        fillRule="evenodd"
        d="m43.868 17.716.002.22v.53c0 1.767-.376 3.28-1.129 4.538a7.462 7.462 0 0 1-3.087 2.882c-1.305.662-2.777.993-4.414.993-1.638 0-3.11-.33-4.415-.993a7.462 7.462 0 0 1-3.087-2.882c-.752-1.259-1.128-2.771-1.128-4.538v-.53c0-1.767.376-3.28 1.128-4.538a7.462 7.462 0 0 1 3.087-2.882c1.306-.662 2.777-.994 4.415-.994 1.596 0 3.034.315 4.315.944V2.531c0-1.198.966-2.17 2.157-2.17 1.192 0 2.158.972 2.158 2.17V17.72l-.002-.003Zm-5.442 4.228c-.84.817-1.903 1.226-3.186 1.226-1.284 0-2.346-.409-3.187-1.226-.84-.84-1.261-2.032-1.261-3.578v-.33c0-1.547.42-2.728 1.261-3.545.841-.84 1.903-1.259 3.187-1.259 1.305 0 2.367.42 3.186 1.259.841.817 1.262 1.998 1.262 3.544v.331c0 1.546-.42 2.739-1.262 3.578Z"
        clipRule="evenodd"
      />
      <path
        fill="#3EC273"
        d="M22.055 7.594a2.644 2.644 0 0 0 2.637-2.652 2.644 2.644 0 0 0-2.637-2.652 2.644 2.644 0 0 0-2.637 2.652 2.644 2.644 0 0 0 2.637 2.652ZM22.055 26.88a2.164 2.164 0 0 1-2.158-2.17V11.692c0-1.198.966-2.17 2.158-2.17 1.191 0 2.157.972 2.157 2.17V24.71c0 1.198-.966 2.17-2.157 2.17Z"
      />
      <path
        fill="#3EC273"
        fillRule="evenodd"
        d="M2.74 15.928c.159-1.636 1.63-5.271 6.793-6.24 6.547-1.229 9.582 4.662 7.111 8.097-2.47 3.436-7.111 3.681-9.245 3.436.632.981 2.75 2.552 6.164.981 3.163-.981 4.03 2.7 1.422 3.926-2.608 1.227-10.519 1.985-12.652-6.358-.738-.257-2.267-.804-2.077-2.375.19-1.57 1.59-1.774 2.485-1.467Zm3.948 1.121c.337-1.656 1.959-4.608 5.926-3.19 3.068 1.339-.634 4.108-5.926 3.19Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h70v27H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default LogoLight;
