import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

function loadGoogleFonts() {
  const link = document.createElement("link");
  link.href =
    "https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap";
  link.rel = "stylesheet";
  document.head.appendChild(link);

  const link2 = document.createElement("link");
  link2.href =
    "https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500;700&display=swap";
  link2.rel = "stylesheet";
  document.head.appendChild(link2);
}

loadGoogleFonts();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
