import styled from "styled-components";

export const DesktopContainer = styled.div`
  padding-top: 120px;
  flex-direction: column;
  align-items: center;
  width: 80%;
  display: flex;
  flex-direction: column;

  img {
    width: 80%;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const MobileContainer = styled.div`
  padding-top: 120px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  display: none;
  padding: 24px;

  @media (max-width: 768px) {
    display: flex;
  }
`;

export const MobileTopSection = styled.img`
  margin-top: 130px;
`;

export const MobileMiddleSection = styled.img`
  margin-top: 48px;
`;

export const MainImage = styled.img`
  margin-bottom: 14px;
`;

export const TitleImage = styled.img`
  margin-top: 36px;
  margin-bottom: 14px;
`;

export const Button = styled.button`
  margin-top: 36px;
  padding: 12px 24px 12px 24px;
  gap: 8px;
  border-radius: 100px;
  border-width: 0px;
  opacity: 0px;
  background-color: #3ec273;
  color: #fcfcfc;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
`;

export const ButtonSubtitle = styled.text`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: #48484a;
`;

export const Banner = styled.img`
  margin-top: 48px;
`;

export const FeaturesSection = styled.img`
  margin-top: 96px;
`;
