import styled from "styled-components";

export const StyledFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #0d0d0f;
  padding: 20px 96px;
  background-color: #fcfcfc;
`;

export const Title = styled.h1`
  margin: 0;
`;

export const Navigation = styled.nav`
  display: flex;
  gap: 36px;
`;

export const NavLink = styled.a`
  color: black;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
