import React from "react";
import QRCode from "../../assets/QRCode.svg";
import { styles } from "./QRModal.styles";
import Modal from "react-modal";

interface QRModalProps {
  visible: boolean;
  closeModal: () => void;
}

const QRModal = ({ visible, closeModal }: QRModalProps) => {
  return (
    <Modal
      isOpen={visible}
      shouldCloseOnOverlayClick={true}
      onRequestClose={closeModal}
      style={styles}
    >
      <img src={QRCode} alt="" />
    </Modal>
  );
};

export default QRModal;
