import MainShowcase from "../../assets/MainShowcase.svg";
import Title from "../../assets/Title.svg";
import FeaturesSection from "../../assets/FeaturesSection.svg";
import GetTheApp from "../../assets/GetTheApp";
import MobileTopSection from "../../assets/mobile/TopSection.svg";
import MobileMiddleSection from "../../assets/mobile/MiddleSection.svg";
import * as S from "./Home.styles";

interface HomeProps {
  onModalOpen: () => void;
}

const Home = ({ onModalOpen }: HomeProps) => {
  return (
    <>
      <S.DesktopContainer>
        <S.MainImage src={MainShowcase} alt="Eido News" />
        <S.TitleImage src={Title} alt="Eido News" />
        <S.Button onClick={onModalOpen}>Pobierz aplikację</S.Button>
        <S.ButtonSubtitle>Dostępna na iOS oraz Android</S.ButtonSubtitle>
        <S.FeaturesSection src={FeaturesSection} alt="Eido News" />
        <GetTheApp onModalOpen={onModalOpen} />
      </S.DesktopContainer>
      <S.MobileContainer>
        <S.MobileTopSection src={MobileTopSection} alt="Eido News" />
        <S.MobileMiddleSection src={MobileMiddleSection} alt="Eido News" />
      </S.MobileContainer>
    </>
  );
};

export default Home;
